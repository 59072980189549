/* eslint-disable no-undef */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

import loadingIcon from '../../../public/images/loader.gif';

import './Loader.style';

/**
 * Loader component
 * Loaders overlay to identify loading
 * @class Loader
 * @namespace Spridevpwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <img src={ loadingIcon } alt="Loader" />
            </div>
        );
    }
}

export default LoaderComponent;
